import React from "react";

const SvgDownload = props => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    style={{
      color: "#242134",
      ...props.style
    }}
  >
    <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" fill="currentColor" />
  </svg>
);

export default SvgDownload;
