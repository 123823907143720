import React from 'react';
import { View, Text, Button } from '../components/common';
import Layout from '../components/layout';
import PageTitle from '../components/PageTitle';
import Download from '../components/svg/Download';
import { colors } from '../style';

const PLATFORMS = [
  { name: 'macOS (intel)', link: 'https://download.actualbudget.com/download/dmg' },
  { name: 'macOS (apple m1)', link: 'https://download.actualbudget.com/download/dmg-arm64' },
  { name: 'Windows', link: 'https://download.actualbudget.com/download/exe' },
  {
    name: 'Linux (AppImage)',
    link: 'https://download.actualbudget.com/download/AppImage'
  },
  {
    name: 'iOS',
    link:
      'https://itunes.apple.com/us/app/actual-budget-your-finances/id1444818585'
  },
  {
    name: 'Android',
    link: 'https://play.google.com/store/apps/details?id=com.shiftreset.actual'
  }
];

let DownloadPage = () => {
  return (
    <Layout>
      <View style={{ width: 500, margin: '0 auto', marginTop: 80 }}>
        <View
          style={{ textAlign: 'center', color: colors.n4, marginBottom: 15 }}
        >
          All supported platforms:
        </View>
        {PLATFORMS.map((platform, idx) => (
          <View
            key={platform.name}
            style={{
              flexDirection: 'row',
              border: '1px solid ' + colors.n4,
              borderTop: idx > 0 ? 'none' : null
            }}
          >
            <View
              style={{
                width: '50%',
                borderRight: '1px solid ' + colors.n4,
                padding: '5px 10px'
              }}
            >
              {platform.name}
            </View>
            <View
              style={{
                padding: '5px 10px'
              }}
            >
              <a
                href={platform.link}
                style={{
                  color: colors.p3,
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none'
                }}
              >
                {platform.name === 'Android' ? (
                  'Play Store'
                ) : platform.name === 'iOS' ? (
                  'App Store'
                ) : (
                  <>
                    <Download
                      width={15}
                      height={15}
                      style={{ color: colors.p3, marginRight: 5 }}
                    />
                    <Text>Download</Text>
                  </>
                )}
              </a>
            </View>
          </View>
        ))}
      </View>
    </Layout>
  );
};

export default DownloadPage;
